import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';
import get from 'lodash/get';
import SEO from '../components/SEO';
import Navbar from "../components/Navbar";
import profileImg from '../images/profile.jpg';
import banner from '../images/backdrop.png'
import linkedinImg from "../images/linkedin.png";
import githubImg from "../images/github.png";
import Modal from "../components/Modal"
import Footer from "../components/Footer";

// styles
const classes = {
  relative: 'relative',
  heroImg: 'absolute top-0 w-full h-full bg-center bg-cover',
  pageDiv: 'relative py-16 bg-gray-900',
  pageContainer: 'container mx-auto px-4',
  page: 'relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 border-8 border-blue-200',
  contentGrid: 'px-6',
  contentHeaderVisuals: 'flex flex-wrap justify-center',
  profileImgDiv: 'w-full lg:w-3/12 px-4 lg:order-2 flex justify-center md: mb-20',
  profileImgStyle: 'shadow-xl rounded-full h-auto align-middle border-8 border-blue-200 absolute -m-32 -ml- lg:-ml-32',
  getInTouchDiv: 'w-full lg:w-4/12 px-24 mt-6 lg:order-3 lg:text-right lg:self-center xs: hidden lg:block',
  getInTouchContainer: 'py-6 px-6 mt-32 sm:mt-0',
  iconsDiv: 'w-full lg:w-4/12 px-4 lg:order-1 xs: hidden lg:block',
  iconsContainer: 'flex justify-center py-4 lg:pt-16 pr-7',
  mobileIconsDiv: 'w-full lg:w-4/12 px-4 lg:order-1 md:block lg:hidden',
  icon: 'mr-4 p-3 text-center',
  iconTitle: 'text-sm text-gray-500',
  title: 'text-4xl font-semibold leading-normal mb-2 text-gray-800',
  contentDiv: 'mt-10 py-10 border-t border-gray-300 text-center',
  contentContainer: 'flex flex-wrap justify-center', 
  content: 'w-full lg:w-11/12 px-4',
  container: 'mt-16 flex-none text-lg text-black font-light md:flex-1 md:pl-20 md:pr-20 mb-4 leading-relaxed text-justify',
  title: 'mt-16 lg:mt-0 text-4xl text-gray-900 font-bold',
  date: 'text-gray-600 font-light',
};

const ContentPage = ({ data }) => {
  const post = data.markdownRemark;
  const github = get(data, 'site.siteMetadata.github', false);
  const linkedin = get(data, 'site.siteMetadata.linkedin', false);
debugger;
  return (
    <React.Fragment>
      <Navbar transparent />
      <SEO title={post.frontmatter.title} />
      <main>
        <section className={classes.relative} style={{ height: "400px" }}>
          <div
            className={classes.heroImg}
            style={{
              backgroundImage:
              `url(${banner})`
            }}
          >
          </div>
        </section>
        <section className={classes.pageDiv}>
          <div className={classes.pageContainer}>
            <div className={classes.page}>
              <div className={classes.contentGrid}>
                <div className={classes.contentHeaderVisuals}>
                  <div className={classes.profileImgDiv}>
                    <div className={classes.relative}>
                      <img
                        alt="..."
                        src={profileImg}
                        className={classes.profileImgStyle}
                        style={{ maxWidth: "250px" }}
                      />
                    </div>
                  </div>
                  <div className={classes.getInTouchDiv}>
                    <div className={classes.getInTouchContainer}>
                      <Modal />
                    </div>
                  </div>
                  <div className={classes.iconsDiv}>
                    <div className={classes.iconsContainer}>
                      <div className={classes.icon}>
                        <a href={linkedin}> 
                          <img
                            alt="..."
                            src={linkedinImg}
                            className="shadow-xl pl-1"
                            style={{ maxWidth: "55px" }}
                          />
                          <span className={classes.iconTitle}>LinkedIn</span>
                        </a>
                      </div>
                      <div className={classes.icon}>
                        <a href={github}>
                          <img
                            alt="..."
                            src={githubImg}
                            className="shadow-xl rounded-full "
                            style={{ maxWidth: "44px" }}
                          />
                          <span className={classes.iconTitle}>Github</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12 lg:mt-0">
                  <h3 className={classes.title}>
                  {post.frontmatter.title}
                  </h3>
                  <div className="mt-10">
                    {post.frontmatter.stack ? `Stack: ` + post.frontmatter.stack : null}
                  </div>
                </div>
                <div className={classes.contentDiv}>
                  <div className={classes.contentContainer}>
                    <div className={classes.content}>
                    <p className={classes.date}>
                    Posted on {moment(post.frontmatter.date).format('MMMM D, YYYY')}
                    </p>
                    <div 
                      className={classes.container}
                      dangerouslySetInnerHTML={{ __html: post.html }}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
      
  );
};

export default ContentPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        stack
      }
    }
  }
`;
